import React from "react"
import { graphql } from "gatsby"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"
import sha256 from "js-sha256"

export const query = graphql`
  query SHA256DecodeQuery {
    allToolsJson(filter: { path: { eq: "hash/sha256" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class SHA256DecodePage extends React.Component<{ data: any }> {
  render() {
    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          transformer={sha256}
          exceptionHandler={e => {
            if (e) {
              return "Invalid input"
            }
          }}
          inputProps={{
            placeHolderText: "Input text here",
          }}
          outputProps={{
            placeHolderText: "Output will be shown here",
          }}
        />
      </ToolLayout>
    )
  }
}

export default SHA256DecodePage
